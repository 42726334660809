<template>
<main id="main" class="reminder">
  <div class="wrap1160">
    <div class="remind-msg">
      <p class="txtCoCor remind-txt-att" v-html="$t('REISSUE_PASSWORD_EXPLANATION_MESSAGE')"></p>
      <p class="mt15" v-html="$t('REISSUE_PASSWORD_CAUTION_MESSAGE')"></p>
    </div>
    <section id="login-form" class="bgLGray">
      <form>
        <table class="tbl-login">
          <tbody>
            <tr>
              <th>
                {{$t('REISSUE_PASSWORD_FORM_EMAIL_LABEL')}}
              </th>
              <td>
                <input type="email" v-bind:class="{'ime-dis': true, 'err': errors.email}" v-model="model.email" required ref="email">
                <p class="err-txt">{{errors.email}}</p>
              </td>
            </tr>
            <tr>
              <th>
                {{$t('REISSUE_PASSWORD_FORM_CONFIRM_EMAIL_LABEL')}}
              </th>
              <td>
                <input type="email" v-bind:class="{'ime-dis': true, 'err': errors.confirmEmail}" v-model="model.confirmEmail" required ref="confirmEmail">
                <p class="err-txt">{{errors.confirmEmail}}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="id-pass-err">
          <p class="err-txt">{{errors.errorMessage}}</p>
        </div>
        <div class="btn-form">
          <input type="button" id="sbm-login" :value="$t('REISSUE_PASSWORD_FORM_SUBMIT_LABEL')" @click="reissuePassword">
        </div>
      </form>
    </section>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    return {
      loadingStore
    }
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
      model  : {},
      errors : {}
    }
  },
  methods : {
    reissuePassword() {
      this.errors = {}
      if (this.model.email !== this.model.confirmEmail) {
        this.errors.confirmEmail = this.$t('MAIL_CONFIRM_ERROR_MESSAGE')
        return
      }
      this.loadingStore.setLoading(true)
      const params = {
        id    : this.model.id,
        email : this.model.email
      }
      Methods.apiExcute('reissue-password', this.$i18n.locale, params).then(data => {
        this.loadingStore.setLoading(false)
        this.parent.completedFlag = true
      })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    }
  },
  watch : {
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0 && this.$refs[keys[0]]) {
        this.$refs[keys[0]].focus()
      }
    }
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

<style scoped lang="scss">
  .tbl-login {
    p.err-txt {
      max-width: 280px !important;
    }
  }
</style>
