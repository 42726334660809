<template>
<main id="main" class="reminder">
  <div class="wrap1160">
    <div class="remind-msg-comp">
      <p v-html="$t('REISSUE_PASSWORD_COMPLETION_MESSAGE')"></p>
      <div class="remind-comp-btn">
        <a class="btnBsc-Black" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t('REISSUE_PASSWORD_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL')}}<img src="@/assets/img/common/ic_arrow_white.svg" alt="矢印"></a>
      </div>
    </div>
  </div>
</main>
</template>

<script>

export default {
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
    }
  },
  methods : {
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
